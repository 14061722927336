import {contentTypes} from "../../../../../di/types";
import {GetFaqListUseCase} from "../../../application";

export function useContentFaq() {
  const {$container} = useNuxtApp();
  const getFaqListUseCase: GetFaqListUseCase = $container.get<GetFaqListUseCase>(contentTypes.GetFaqListUseCase);

  const isLoading = ref(false);
  const faqList = ref();

  async function fetchFaqList(id: string) {
    isLoading.value = true;
    try {
      faqList.value = await getFaqListUseCase.getFaqListById(id);
    } catch (e) {
      // console.log(e);
      throw e;
    } finally {
      isLoading.value = false;
    }
  }

  return {
    isLoading,
    faqList,
    fetchFaqList,
  }
}
